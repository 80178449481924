import { PrimaryButtonRouterLink } from 'components/Button';
import { CmsApiStickyHeaderLinksBlockData } from 'modules/cms/api/types/blocks/CmsApiStickyHeaderLinksBlock';
import { isUrlWithProtocol } from 'utils/url/isUrlWithProtocol';

import { CmsStickyHeaderLinks } from './CmsStickyHeaderLinks';
import { CmsStickyHeaderLinksBlockExternalLink } from './CmsStickyHeaderLinksBlockExternalLink';

type Props = {
  data: CmsApiStickyHeaderLinksBlockData;
};

export function CmsStickyHeaderLinksBlock({
  data: { links, buttonText, buttonUrl },
}: Props) {
  const hasButton = buttonUrl && buttonText;
  return (
    <CmsStickyHeaderLinks
      links={links}
      button={
        hasButton &&
        (buttonUrl && isUrlWithProtocol(buttonUrl) ? (
          <CmsStickyHeaderLinksBlockExternalLink href={buttonUrl}>
            {buttonText}
          </CmsStickyHeaderLinksBlockExternalLink>
        ) : (
          <PrimaryButtonRouterLink to={buttonUrl}>
            {buttonText}
          </PrimaryButtonRouterLink>
        ))
      }
    />
  );
}
