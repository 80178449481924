import { ComponentProps } from 'react';

import { RouterButton } from 'components/Button/RouterButton';

type Props = ComponentProps<typeof RouterButton>;

export function PrimaryButtonRouterLink({ ...props }: Props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RouterButton variant="primary" {...props} />;
}
